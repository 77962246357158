import { useQueries, useQuery } from '@tanstack/react-query';

import { fetchContentById, getLanguages } from 'globals/cms-helper/layoutApi';
import { TLanguage } from 'globals/types/language';
import { ALL_CONTENT, QUERY_BY_CONTENT_IDS } from 'utils/constants';

export const usePreviewQueries = (previewID: string, previewDate: string) => {
  const { data: languages } = useQuery<TLanguage[]>({
    queryKey: [QUERY_BY_CONTENT_IDS.ACTIVE_LANGS],
    queryFn: () => getLanguages(QUERY_BY_CONTENT_IDS.ACTIVE_LANGS),
    enabled: !!previewID
  });

  const allContents = useQueries({
    queries: languages?.length
      ? languages?.map((language: TLanguage) => {
          return {
            queryKey: [ALL_CONTENT, language.code],
            queryFn: () => fetchContentById(ALL_CONTENT, { language, depth: 1, previewDate }),
            enabled: !!previewID && languages?.length > 0
          };
        })
      : []
  });

  const allContentsExistInPreviewForAllLangs =
    !!previewID && allContents?.length && allContents?.every((content) => !!content?.data);

  return allContentsExistInPreviewForAllLangs;
};
